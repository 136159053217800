import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import VueQrcode from '@chenfengyuan/vue-qrcode'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ru'

Vue.component(DatePicker.name, DatePicker)
Vue.component(FeatherIcon.name, FeatherIcon)
Vue.component(VueQrcode.name, VueQrcode)
