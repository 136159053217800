import axios from '@axios'

export default {
  namespaced: true,
  state: {
    cartItemsCount: (() => {
      const userData = JSON.parse(localStorage.getItem('userData'))
      return userData && userData.extras ? userData.extras.eCommerceCartItemsCount : 0
    })(),
    wishlistItemsCount: (() => {
      const userData = JSON.parse(localStorage.getItem('userData'))
      return userData && userData.extras ? userData.extras.eCommerceWishItemsCount : 0
    })(),
    cartTotals: null,
    cartPaymentTotals: null,
    paumentData: null,
    cartPayments: null,
    cartAddress: null,
    orderData: {},
  },
  getters: {},
  mutations: {
    UPDATE_CART_ITEMS_COUNT(state, count) {
      state.cartItemsCount = count
      const userData = JSON.parse(localStorage.getItem('userData'))
      if (userData && userData.extras) {
        userData.extras.eCommerceCartItemsCount = count
        localStorage.setItem('userData', JSON.stringify(userData))
      }
    },
    UPDATE_WISHLIST_ITEMS_COUNT(state, count) {
      state.wishlistItemsCount = count
      const userData = JSON.parse(localStorage.getItem('userData'))
      if (userData && userData.extras) {
        userData.extras.eCommerceWishItemsCount = count
        localStorage.setItem('userData', JSON.stringify(userData))
      }
    },
    UPDATE_CART_TOTALS(state, totals) {
      state.cartTotals = null
      state.cartTotals = totals
    },
    UPDATE_CART_PAYMENT_DATA(state, paumentData) {
      state.paumentData = paumentData
    },
    UPDATE_CART_PAYMENT_TOTALS(state, totals) {
      state.cartPaymentTotals = totals
    },
    UPDATE_CART_ADDRESS(state, address) {
      if (!address.address.town_select) {
        // eslint-disable-next-line
        address.address = { town_select: [{}] }
      }
      state.cartAddress = address
    },
    UPDATE_PAYMENTS(state, payments) {
      state.cartPayments = payments
    },
    UPDATE_ORDER_DATA(state, payload) {
      state.orderData = {
        ...state.orderData,
        ...payload,
      }
    },
  },
  actions: {
    async shopMSG() {
      return axios.get('htm/lk/service/data/page/shopMSG')
    },
    async fetchData(context, payload) {
      return axios.get('json/lk/buyer/search/list/town', payload)
    },
    fetchProducts(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get('json/lk/shop/products/grid', {
            params: payload.params,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCategories() {
      return new Promise((resolve, reject) => {
        axios
          .get('json/lk/service/data/list/prodTypeALL')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPriceRange() {
      return new Promise((resolve, reject) => {
        axios
          .get('json/lk/service/data/page/priceRange')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchRelatedProducts(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`json/lk/shop/products/grid/related/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProduct(ctx, { productId }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`json/lk/shop/products/item/${productId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchWishlistProducts() {
      return new Promise((resolve, reject) => {
        axios
          .get('json/lk/shop/products/grid/wish')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCartProducts() {
      return new Promise((resolve, reject) => {
        axios
          .get('json/lk/shop/products/grid/cart')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCartPriceDetail() {
      return new Promise((resolve, reject) => {
        axios
          .get('json/lk/shop/CheckOut/page/PriceDetail')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCartAndPriceDetail() {
      return new Promise((resolve, reject) => {
        axios
          .get('json/lk/shop/CheckOut/page/cart')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCartPack(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('json/lk/shop/CheckOut/page/pack')
          .then(response => {
            const { data } = response.data
            if (data) {
              const pack = data.filter(item => item.default === true)[0]
              ctx.commit('UPDATE_ORDER_DATA', {
                pack: {
                  value: pack.value,
                  price: pack.price,
                },
              })
            }

            return resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    addCartPack(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('json/lk/shop/CheckOut/add/pack', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCartAddress(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('json/lk/shop/CheckOut/page/address')
          .then(response => {
            const { data } = response.data

            if (data && data[0] && data[0].delivery) {
              const delivery = data[0].delivery.filter(item => item.default === true)[0]
              ctx.commit('UPDATE_ORDER_DATA', {
                address: {
                  ...data[0],
                  delivery: {
                    value: delivery.value,
                    price: delivery.price,
                  },
                },
              })
            }

            return resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    addCartAddress(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('json/lk/shop/CheckOut/add/address', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCartPay(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('json/lk/shop/CheckOut/page/pay')
          .then(response => {
            const { data } = response.data

            if (data && data[0] && data[0].payments) {
              let payment = data[0].payments.filter(item => item.default === true)[0]
              if (!payment) {
                // eslint-disable-next-line
                payment = data[0].payments[0]
              }
              if (!ctx.state.orderData.pay || !ctx.state.orderData.pay.value) {
                ctx.commit('UPDATE_ORDER_DATA', {
                  pay: {
                    value: payment.value,
                    accSum: data[0].acc.balanceForPay,
                  },
                })
              }
              ctx.commit('UPDATE_PAYMENTS', data[0].payments)
            }

            return resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    addCartPay(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('json/lk/shop/CheckOut/set/pay', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    sendOrder(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .post('json/lk/shop/CheckOut/add', {
            xparam1: JSON.stringify(ctx.state.orderData),
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    sendPaykeeperOrder(ctx, payload) {
      return new Promise((resolve, reject) => {
        const formData = []

        /* eslint-disable */
        if (payload.data) {
          for (const key in payload.data) {
            // if (key === 'cart') {
            //   if (payload.data[key]) {
            //     formData.append(key, JSON.stringify(payload.data[key]))
            //   }
            // } else {
            //   formData.append(key, payload.data[key])
            // }

            const encodedKey = encodeURIComponent(key)
            const encodedValue = encodeURIComponent(payload.data[key])
            formData.push(`${encodedKey}=${encodedValue}`);
          }
        }

        axios({
          url: payload.url,
          method: 'POST',
          data: formData.join('&'),
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/x-www-form-urlencoded; charset=utf-8',
            withCredentials: true,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
        /* eslint-enable */
      })
    },
    // ------------------------------------------------
    // Product Actions
    // ------------------------------------------------
    addProductInWishlist(ctx, productId) {
      return new Promise((resolve, reject) => {
        axios
          .post(`json/lk/shop/products/add/wish/${productId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeProductFromWishlist(ctx, productId) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`json/lk/shop/products/del/wish/${productId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    addProductInCart(ctx, productId) {
      return new Promise((resolve, reject) => {
        axios
          .post(`json/lk/shop/products/add/cart/${productId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addProductTestMode(ctx, productId) {
      return new Promise((resolve, reject) => {
        axios
          .post(`json/lk/shop/products/add/testmode/${productId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeProductTestMode(ctx, productId) {
      return new Promise((resolve, reject) => {
        axios
          .post(`json/lk/shop/products/del/testmode/${productId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeProductFromCart(ctx, productId) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`json/lk/shop/products/del/cart/${productId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeProductFromCartAll(ctx, productId) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`json/lk/shop/products/del/cartall/${productId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
