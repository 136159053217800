import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import VueMask from 'v-mask'
import VueSocialSharing from 'vue-social-sharing'
import VueClipboard from 'vue-clipboard2'

import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'

import i18n from '@/libs/i18n'

import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/sweet-alerts'
import '@/libs/toastification'

const fa10 = {
  prefix: 'fas',
  iconName: '10',
  icon: [
    320, 512,
    [],
    null,
    'M 25 64 C 25 52.2 18.5 41.4 8.1 35.8 C -2.3 30.2 -14.9 30.8 -24.8 37.4 L -120.8 101.4 C -135.5 111.2 -139.4 131 -129.6 145.8 C -119.8 160.6 -99.9 164.5 -85.2 154.7 L -39 123.8 L -39 416 L -103 416 C -120.7 416 -135 430.3 -135 448 C -135 465.7 -120.7 480 -103 480 L -7 480 L 89 480 C 106.7 480 121 465.7 121 448 C 121 430.3 106.7 416 89 416 L 25 416 L 25 64 ZM 134.239 191.953 C 134.239 103.553 205.839 31.953 294.239 31.953 C 382.639 31.953 454.239 103.553 454.239 191.953 L 454.239 319.953 C 454.239 408.353 382.639 479.953 294.239 479.953 C 205.839 479.953 134.239 408.353 134.239 319.953 L 134.239 191.953 Z M 294.239 95.953 C 241.239 95.953 198.239 138.953 198.239 191.953 L 198.239 319.953 C 198.239 372.953 241.239 415.953 294.239 415.953 C 347.239 415.953 390.239 372.953 390.239 319.953 L 390.239 191.953 C 390.239 138.953 347.239 95.953 294.239 95.953 Z',
  ],
}
const fa11 = {
  prefix: 'fas',
  iconName: '11',
  icon: [
    320, 512,
    [],
    null,
    'M 55 64 C 55 52.2 48.5 41.4 38.1 35.8 C 27.7 30.2 15.1 30.8 5.2 37.4 L -90.8 101.4 C -105.5 111.2 -109.4 131 -99.6 145.8 C -89.8 160.6 -69.9 164.5 -55.2 154.7 L -9 123.8 L -9 416 L -73 416 C -90.7 416 -105 430.3 -105 448 C -105 465.7 -90.7 480 -73 480 L 23 480 L 119 480 C 136.7 480 151 465.7 151 448 C 151 430.3 136.7 416 119 416 L 55 416 L 55 64 ZM 328.18 64.504 C 328.18 52.704 321.68 41.904 311.28 36.304 C 300.88 30.704 288.28 31.304 278.38 37.904 L 182.38 101.904 C 167.68 111.704 163.78 131.504 173.58 146.304 C 183.38 161.104 203.28 165.004 217.98 155.204 L 264.18 124.304 L 264.18 416.504 L 200.18 416.504 C 182.48 416.504 168.18 430.804 168.18 448.504 C 168.18 466.204 182.48 480.504 200.18 480.504 L 296.18 480.504 L 392.18 480.504 C 409.88 480.504 424.18 466.204 424.18 448.504 C 424.18 430.804 409.88 416.504 392.18 416.504 L 328.18 416.504 L 328.18 64.504 Z',
  ],
}

const fa12 = {
  prefix: 'fas',
  iconName: '12',
  icon: [
    320, 512,
    [],
    null,
    'M 25 64 C 25 52.2 18.5 41.4 8.1 35.8 C -2.3 30.2 -14.9 30.8 -24.8 37.4 L -120.8 101.4 C -135.5 111.2 -139.4 131 -129.6 145.8 C -119.8 160.6 -99.9 164.5 -85.2 154.7 L -39 123.8 L -39 416 L -103 416 C -120.7 416 -135 430.3 -135 448 C -135 465.7 -120.7 480 -103 480 L -7 480 L 89 480 C 106.7 480 121 465.7 121 448 C 121 430.3 106.7 416 89 416 L 25 416 L 25 64 ZM 279.612 96.095 C 258.112 96.095 237.412 104.595 222.212 119.895 L 191.312 150.695 C 178.812 163.195 158.512 163.195 146.012 150.695 C 133.512 138.195 133.512 117.895 146.012 105.395 L 176.912 74.595 C 204.212 47.395 241.112 32.095 279.612 32.095 C 359.712 32.095 424.712 97.095 424.712 177.195 C 424.712 215.695 409.412 252.595 382.212 279.795 L 246.012 416.095 L 424.712 416.095 C 442.412 416.095 456.712 430.395 456.712 448.095 C 456.712 465.795 442.412 480.095 424.712 480.095 L 168.712 480.095 C 155.812 480.095 144.112 472.295 139.112 460.295 C 134.112 448.295 136.912 434.595 146.012 425.395 L 336.912 234.595 C 352.112 219.395 360.712 198.695 360.712 177.195 C 360.712 132.395 324.412 96.095 279.612 96.095 Z',
  ],
}

const fa13 = {
  prefix: 'fas',
  iconName: '13',
  icon: [
    320, 512,
    [],
    null,
    'M 26.851 64 C 26.851 52.2 20.351 41.4 9.951 35.8 C -0.449 30.2 -13.049 30.8 -22.949 37.4 L -118.949 101.4 C -133.649 111.2 -137.549 131 -127.749 145.8 C -117.949 160.6 -98.049 164.5 -83.349 154.7 L -37.149 123.8 L -37.149 416 L -101.149 416 C -118.849 416 -133.149 430.3 -133.149 448 C -133.149 465.7 -118.849 480 -101.149 480 L -5.149 480 L 90.851 480 C 108.551 480 122.851 465.7 122.851 448 C 122.851 430.3 108.551 416 90.851 416 L 26.851 416 L 26.851 64 ZM 132.455 64.604 C 132.455 46.904 146.755 32.604 164.455 32.604 L 404.455 32.604 C 417.655 32.604 429.455 40.704 434.255 53.004 C 439.055 65.304 435.755 79.304 426.055 88.204 L 294.755 208.604 L 316.455 208.604 C 391.555 208.604 452.455 269.504 452.455 344.604 C 452.455 419.704 391.555 480.604 316.455 480.604 L 237.855 480.604 C 195.455 480.604 156.655 456.604 137.655 418.704 L 135.755 414.904 C 127.855 399.104 134.255 379.904 150.055 372.004 C 165.855 364.104 185.055 370.504 192.955 386.304 L 194.855 390.104 C 202.955 406.404 219.655 416.604 237.755 416.604 L 316.455 416.604 C 356.255 416.604 388.455 384.404 388.455 344.604 C 388.455 304.804 356.255 272.604 316.455 272.604 L 212.455 272.604 C 199.255 272.604 187.455 264.504 182.655 252.204 C 177.855 239.904 181.155 225.904 190.855 217.004 L 322.155 96.604 L 164.455 96.604 C 146.755 96.604 132.455 82.304 132.455 64.604 Z',
  ],
}

const fa14 = {
  prefix: 'fas',
  iconName: '14',
  icon: [
    320, 512,
    [],
    null,
    'M -10.607 64 C -10.607 52.2 -17.107 41.4 -27.507 35.8 C -37.907 30.2 -50.507 30.8 -60.407 37.4 L -156.407 101.4 C -171.107 111.2 -175.007 131 -165.207 145.8 C -155.407 160.6 -135.507 164.5 -120.807 154.7 L -74.607 123.8 L -74.607 416 L -138.607 416 C -156.307 416 -170.607 430.3 -170.607 448 C -170.607 465.7 -156.307 480 -138.607 480 L -42.607 480 L 53.393 480 C 71.093 480 85.393 465.7 85.393 448 C 85.393 430.3 71.093 416 53.393 416 L -10.607 416 L -10.607 64 ZM 294.473 77.939 C 301.973 61.939 295.173 42.839 279.173 35.339 C 263.173 27.839 244.073 34.639 236.573 50.639 L 108.473 322.739 C 103.773 332.639 104.573 344.239 110.373 353.539 C 116.173 362.839 126.473 368.339 137.473 368.339 L 361.473 368.339 L 361.473 448.339 C 361.473 466.039 375.773 480.339 393.473 480.339 C 411.173 480.339 425.473 466.039 425.473 448.339 L 425.473 368.339 L 457.473 368.339 C 475.173 368.339 489.473 354.039 489.473 336.339 C 489.473 318.639 475.173 304.339 457.473 304.339 L 425.473 304.339 L 425.473 160.339 C 425.473 142.639 411.173 128.339 393.473 128.339 C 375.773 128.339 361.473 142.639 361.473 160.339 L 361.473 304.339 L 187.873 304.339 L 294.473 77.939 Z',
  ],
}

const fa15 = {
  prefix: 'fas',
  iconName: '15',
  icon: [
    320, 512,
    [],
    null,
    'M 24.932 64 C 24.932 52.2 18.432 41.4 8.032 35.8 C -2.368 30.2 -14.968 30.8 -24.868 37.4 L -120.868 101.4 C -135.568 111.2 -139.468 131 -129.668 145.8 C -119.868 160.6 -99.968 164.5 -85.268 154.7 L -39.068 123.8 L -39.068 416 L -103.068 416 C -120.768 416 -135.068 430.3 -135.068 448 C -135.068 465.7 -120.768 480 -103.068 480 L -7.068 480 L 88.932 480 C 106.632 480 120.932 465.7 120.932 448 C 120.932 430.3 106.632 416 88.932 416 L 24.932 416 L 24.932 64 ZM 166.069 58.309 C 168.869 43.109 182.069 32.009 197.569 32.009 L 389.569 32.009 C 407.269 32.009 421.569 46.309 421.569 64.009 C 421.569 81.709 407.269 96.009 389.569 96.009 L 224.269 96.009 L 203.869 208.009 L 317.569 208.009 C 392.669 208.009 453.569 268.909 453.569 344.009 C 453.569 419.109 392.669 480.009 317.569 480.009 L 234.069 480.009 C 194.669 480.009 158.669 457.709 141.069 422.509 L 136.969 414.309 C 129.069 398.509 135.469 379.309 151.269 371.409 C 167.069 363.509 186.269 369.909 194.169 385.709 L 198.269 393.909 C 205.069 407.509 218.869 416.009 234.069 416.009 L 317.569 416.009 C 357.369 416.009 389.569 383.809 389.569 344.009 C 389.569 304.209 357.369 272.009 317.569 272.009 L 165.569 272.009 C 156.069 272.009 147.069 267.809 140.969 260.509 C 134.869 253.209 132.369 243.609 134.069 234.309 L 166.069 58.309 Z',
  ],
}
const fa16 = {
  prefix: 'fas',
  iconName: '16',
  icon: [
    320, 512,
    [],
    null,
    'M 11.927 64 C 11.927 52.2 5.427 41.4 -4.973 35.8 C -15.373 30.2 -27.973 30.8 -37.873 37.4 L -133.873 101.4 C -148.573 111.2 -152.473 131 -142.673 145.8 C -132.873 160.6 -112.973 164.5 -98.273 154.7 L -52.073 123.8 L -52.073 416 L -116.073 416 C -133.773 416 -148.073 430.3 -148.073 448 C -148.073 465.7 -133.773 480 -116.073 480 L -20.073 480 L 75.927 480 C 93.627 480 107.927 465.7 107.927 448 C 107.927 430.3 93.627 416 75.927 416 L 11.927 416 L 11.927 64 ZM 354.996 84.999 C 366.396 71.499 364.696 51.299 351.196 39.899 C 337.696 28.499 317.496 30.199 306.096 43.699 L 161.196 214.999 C 137.296 243.199 123.696 278.699 122.696 315.499 C 122.696 316.899 122.596 318.399 122.596 319.799 C 122.596 319.999 122.596 320.099 122.596 320.299 C 122.596 408.699 194.196 480.299 282.596 480.299 C 370.996 480.299 442.596 408.699 442.596 320.299 C 442.596 234.799 375.496 164.899 291.096 160.499 L 354.996 84.899 L 354.996 84.999 Z M 186.596 320.299 C 186.596 267.299 229.596 224.299 282.596 224.299 C 335.596 224.299 378.596 267.299 378.596 320.299 C 378.596 373.299 335.596 416.299 282.596 416.299 C 229.596 416.299 186.596 373.299 186.596 320.299 Z',
  ],
}
const fa17 = {
  prefix: 'fas',
  iconName: '17',
  icon: [
    320, 512,
    [],
    null,
    'M 28.988 64 C 28.988 52.2 22.488 41.4 12.088 35.8 C 1.688 30.2 -10.912 30.8 -20.812 37.4 L -116.812 101.4 C -131.512 111.2 -135.412 131 -125.612 145.8 C -115.812 160.6 -95.912 164.5 -81.212 154.7 L -35.012 123.8 L -35.012 416 L -99.012 416 C -116.712 416 -131.012 430.3 -131.012 448 C -131.012 465.7 -116.712 480 -99.012 480 L -3.012 480 L 92.988 480 C 110.688 480 124.988 465.7 124.988 448 C 124.988 430.3 110.688 416 92.988 416 L 28.988 416 L 28.988 64 ZM 104.497 62.966 C 104.497 45.266 118.797 30.966 136.497 30.966 L 392.497 30.966 C 403.997 30.966 414.497 37.066 420.197 47.066 C 425.897 57.066 425.897 69.266 420.097 79.166 L 196.097 463.166 C 187.197 478.466 167.597 483.566 152.297 474.666 C 136.997 465.766 131.897 446.166 140.797 430.866 L 336.797 94.966 L 136.497 94.966 C 118.797 94.966 104.497 80.666 104.497 62.966 Z',
  ],
}
const fa18 = {
  prefix: 'fas',
  iconName: '18',
  icon: [
    320, 512,
    [],
    null,
    'M 13.376 64 C 13.376 52.2 6.876 41.4 -3.524 35.8 C -13.924 30.2 -26.524 30.8 -36.424 37.4 L -132.424 101.4 C -147.124 111.2 -151.024 131 -141.224 145.8 C -131.424 160.6 -111.524 164.5 -96.824 154.7 L -50.624 123.8 L -50.624 416 L -114.624 416 C -132.324 416 -146.624 430.3 -146.624 448 C -146.624 465.7 -132.324 480 -114.624 480 L -18.624 480 L 77.376 480 C 95.076 480 109.376 465.7 109.376 448 C 109.376 430.3 95.076 416 77.376 416 L 13.376 416 L 13.376 64 ZM 424.417 159.544 C 424.417 88.844 367.117 31.544 296.417 31.544 L 264.417 31.544 C 193.717 31.544 136.417 88.844 136.417 159.544 C 136.417 194.144 150.117 225.544 172.417 248.544 C 140.917 271.844 120.417 309.344 120.417 351.544 C 120.417 422.244 177.717 479.544 248.417 479.544 L 312.417 479.544 C 383.117 479.544 440.417 422.244 440.417 351.544 C 440.417 309.344 419.917 271.844 388.417 248.544 C 410.717 225.544 424.417 194.144 424.417 159.544 Z M 296.517 287.544 L 312.417 287.544 C 347.717 287.544 376.417 316.244 376.417 351.544 C 376.417 386.844 347.717 415.544 312.417 415.544 L 248.417 415.544 C 213.117 415.544 184.417 386.844 184.417 351.544 C 184.417 316.244 213.117 287.544 248.417 287.544 L 264.317 287.544 C 264.317 287.544 264.417 287.544 264.417 287.544 L 296.417 287.544 C 296.417 287.544 296.517 287.544 296.517 287.544 Z M 296.517 223.544 C 296.517 223.544 296.517 223.544 296.517 223.544 L 264.417 223.544 C 264.417 223.544 264.417 223.544 264.417 223.544 C 229.117 223.544 200.417 194.844 200.417 159.544 C 200.417 124.244 229.117 95.544 264.417 95.544 L 296.417 95.544 C 331.717 95.544 360.417 124.244 360.417 159.544 C 360.417 194.844 331.817 223.544 296.417 223.544 L 296.517 223.544 Z',
  ],
}
const fa19 = {
  prefix: 'fas',
  iconName: '19',
  icon: [
    320, 512,
    [],
    null,
    'M 13.376 64 C 13.376 52.2 6.876 41.4 -3.524 35.8 C -13.924 30.2 -26.524 30.8 -36.424 37.4 L -132.424 101.4 C -147.124 111.2 -151.024 131 -141.224 145.8 C -131.424 160.6 -111.524 164.5 -96.824 154.7 L -50.624 123.8 L -50.624 416 L -114.624 416 C -132.324 416 -146.624 430.3 -146.624 448 C -146.624 465.7 -132.324 480 -114.624 480 L -18.624 480 L 77.376 480 C 95.076 480 109.376 465.7 109.376 448 C 109.376 430.3 95.076 416 77.376 416 L 13.376 416 L 13.376 64 ZM 186.098 192.479 C 186.098 245.479 229.098 288.479 282.098 288.479 C 335.098 288.479 378.098 245.479 378.098 192.479 C 378.098 139.479 335.098 96.479 282.098 96.479 C 229.098 96.479 186.098 139.479 186.098 192.479 Z M 273.598 352.279 C 189.198 347.879 122.098 277.979 122.098 192.479 C 122.098 104.079 193.698 32.479 282.098 32.479 C 370.498 32.479 442.098 104.079 442.098 192.479 C 442.098 195.079 441.998 197.779 441.898 200.379 C 440.198 236.079 426.698 270.379 403.498 297.779 L 258.498 469.179 C 247.098 482.679 226.898 484.379 213.398 472.979 C 199.898 461.579 198.198 441.379 209.598 427.879 L 273.498 352.279 L 273.598 352.279 Z',
  ],
}
const fa20 = {
  prefix: 'fas',
  iconName: '20',
  icon: [
    320, 512,
    [],
    null,
    'M -36.911 96.426 C -58.411 96.426 -79.111 104.926 -94.311 120.226 L -125.211 151.026 C -137.711 163.526 -158.011 163.526 -170.511 151.026 C -183.011 138.526 -183.011 118.226 -170.511 105.726 L -139.611 74.926 C -112.311 47.726 -75.411 32.426 -36.911 32.426 C 43.189 32.426 108.189 97.426 108.189 177.526 C 108.189 216.026 92.889 252.926 65.689 280.126 L -70.511 416.426 L 108.189 416.426 C 125.889 416.426 140.189 430.726 140.189 448.426 C 140.189 466.126 125.889 480.426 108.189 480.426 L -147.811 480.426 C -160.711 480.426 -172.411 472.626 -177.411 460.626 C -182.411 448.626 -179.611 434.926 -170.511 425.726 L 20.389 234.926 C 35.589 219.726 44.189 199.026 44.189 177.526 C 44.189 132.726 7.889 96.426 -36.911 96.426 ZM 157.351 192.631 C 157.351 104.231 228.951 32.631 317.351 32.631 C 405.751 32.631 477.351 104.231 477.351 192.631 L 477.351 320.631 C 477.351 409.031 405.751 480.631 317.351 480.631 C 228.951 480.631 157.351 409.031 157.351 320.631 L 157.351 192.631 Z M 317.351 96.631 C 264.351 96.631 221.351 139.631 221.351 192.631 L 221.351 320.631 C 221.351 373.631 264.351 416.631 317.351 416.631 C 370.351 416.631 413.351 373.631 413.351 320.631 L 413.351 192.631 C 413.351 139.631 370.351 96.631 317.351 96.631 Z',
  ],
}
const fa21 = {
  prefix: 'fas',
  iconName: '21',
  icon: [
    320, 512,
    [],
    null,
    'M 346.132 64 C 346.132 52.2 339.632 41.4 329.232 35.8 C 318.832 30.2 306.232 30.8 296.332 37.4 L 200.332 101.4 C 185.632 111.2 181.732 131 191.532 145.8 C 201.332 160.6 221.232 164.5 235.932 154.7 L 282.132 123.8 L 282.132 416 L 218.132 416 C 200.432 416 186.132 430.3 186.132 448 C 186.132 465.7 200.432 480 218.132 480 L 314.132 480 L 410.132 480 C 427.832 480 442.132 465.7 442.132 448 C 442.132 430.3 427.832 416 410.132 416 L 346.132 416 L 346.132 64 ZM -3.386 96.426 C -24.886 96.426 -45.586 104.926 -60.786 120.226 L -91.686 151.026 C -104.186 163.526 -124.486 163.526 -136.986 151.026 C -149.486 138.526 -149.486 118.226 -136.986 105.726 L -106.086 74.926 C -78.786 47.726 -41.886 32.426 -3.386 32.426 C 76.714 32.426 141.714 97.426 141.714 177.526 C 141.714 216.026 126.414 252.926 99.214 280.126 L -36.986 416.426 L 141.714 416.426 C 159.414 416.426 173.714 430.726 173.714 448.426 C 173.714 466.126 159.414 480.426 141.714 480.426 L -114.286 480.426 C -127.186 480.426 -138.886 472.626 -143.886 460.626 C -148.886 448.626 -146.086 434.926 -136.986 425.726 L 53.914 234.926 C 69.114 219.726 77.714 199.026 77.714 177.526 C 77.714 132.726 41.414 96.426 -3.386 96.426 Z',
  ],
}
const fa22 = {
  prefix: 'fas',
  iconName: '22',
  icon: [
    320, 512,
    [],
    null,
    'M -36.911 96.426 C -58.411 96.426 -79.111 104.926 -94.311 120.226 L -125.211 151.026 C -137.711 163.526 -158.011 163.526 -170.511 151.026 C -183.011 138.526 -183.011 118.226 -170.511 105.726 L -139.611 74.926 C -112.311 47.726 -75.411 32.426 -36.911 32.426 C 43.189 32.426 108.189 97.426 108.189 177.526 C 108.189 216.026 92.889 252.926 65.689 280.126 L -70.511 416.426 L 108.189 416.426 C 125.889 416.426 140.189 430.726 140.189 448.426 C 140.189 466.126 125.889 480.426 108.189 480.426 L -147.811 480.426 C -160.711 480.426 -172.411 472.626 -177.411 460.626 C -182.411 448.626 -179.611 434.926 -170.511 425.726 L 20.389 234.926 C 35.589 219.726 44.189 199.026 44.189 177.526 C 44.189 132.726 7.889 96.426 -36.911 96.426 ZM 295.322 96.867 C 273.822 96.867 253.122 105.367 237.922 120.667 L 207.022 151.467 C 194.522 163.967 174.222 163.967 161.722 151.467 C 149.222 138.967 149.222 118.667 161.722 106.167 L 192.622 75.367 C 219.922 48.167 256.822 32.867 295.322 32.867 C 375.422 32.867 440.422 97.867 440.422 177.967 C 440.422 216.467 425.122 253.367 397.922 280.567 L 261.722 416.867 L 440.422 416.867 C 458.122 416.867 472.422 431.167 472.422 448.867 C 472.422 466.567 458.122 480.867 440.422 480.867 L 184.422 480.867 C 171.522 480.867 159.822 473.067 154.822 461.067 C 149.822 449.067 152.622 435.367 161.722 426.167 L 352.622 235.367 C 367.822 220.167 376.422 199.467 376.422 177.967 C 376.422 133.167 340.122 96.867 295.322 96.867 Z',
  ],
}
const fa23 = {
  prefix: 'fas',
  iconName: '23',
  icon: [
    320, 512,
    [],
    null,
    'M -36.911 96.426 C -58.411 96.426 -79.111 104.926 -94.311 120.226 L -125.211 151.026 C -137.711 163.526 -158.011 163.526 -170.511 151.026 C -183.011 138.526 -183.011 118.226 -170.511 105.726 L -139.611 74.926 C -112.311 47.726 -75.411 32.426 -36.911 32.426 C 43.189 32.426 108.189 97.426 108.189 177.526 C 108.189 216.026 92.889 252.926 65.689 280.126 L -70.511 416.426 L 108.189 416.426 C 125.889 416.426 140.189 430.726 140.189 448.426 C 140.189 466.126 125.889 480.426 108.189 480.426 L -147.811 480.426 C -160.711 480.426 -172.411 472.626 -177.411 460.626 C -182.411 448.626 -179.611 434.926 -170.511 425.726 L 20.389 234.926 C 35.589 219.726 44.189 199.026 44.189 177.526 C 44.189 132.726 7.889 96.426 -36.911 96.426 ZM 156.978 64.215 C 156.978 46.515 171.278 32.215 188.978 32.215 L 428.978 32.215 C 442.178 32.215 453.978 40.315 458.778 52.615 C 463.578 64.915 460.278 78.915 450.578 87.815 L 319.278 208.215 L 340.978 208.215 C 416.078 208.215 476.978 269.115 476.978 344.215 C 476.978 419.315 416.078 480.215 340.978 480.215 L 262.378 480.215 C 219.978 480.215 181.178 456.215 162.178 418.315 L 160.278 414.515 C 152.378 398.715 158.778 379.515 174.578 371.615 C 190.378 363.715 209.578 370.115 217.478 385.915 L 219.378 389.715 C 227.478 406.015 244.178 416.215 262.278 416.215 L 340.978 416.215 C 380.778 416.215 412.978 384.015 412.978 344.215 C 412.978 304.415 380.778 272.215 340.978 272.215 L 236.978 272.215 C 223.778 272.215 211.978 264.115 207.178 251.815 C 202.378 239.515 205.678 225.515 215.378 216.615 L 346.678 96.215 L 188.978 96.215 C 171.278 96.215 156.978 81.915 156.978 64.215 Z',
  ],
}
const fa24 = {
  prefix: 'fas',
  iconName: '24',
  icon: [
    320, 512,
    [],
    null,
    'M -67.394 96.426 C -88.894 96.426 -109.594 104.926 -124.794 120.226 L -155.694 151.026 C -168.194 163.526 -188.494 163.526 -200.994 151.026 C -213.494 138.526 -213.494 118.226 -200.994 105.726 L -170.094 74.926 C -142.794 47.726 -105.894 32.426 -67.394 32.426 C 12.706 32.426 77.706 97.426 77.706 177.526 C 77.706 216.026 62.406 252.926 35.206 280.126 L -100.994 416.426 L 77.706 416.426 C 95.406 416.426 109.706 430.726 109.706 448.426 C 109.706 466.126 95.406 480.426 77.706 480.426 L -178.294 480.426 C -191.194 480.426 -202.894 472.626 -207.894 460.626 C -212.894 448.626 -210.094 434.926 -200.994 425.726 L -10.094 234.926 C 5.106 219.726 13.706 199.026 13.706 177.526 C 13.706 132.726 -22.594 96.426 -67.394 96.426 ZM 313.982 77.16 C 321.482 61.16 314.682 42.06 298.682 34.56 C 282.682 27.06 263.582 33.86 256.082 49.86 L 127.982 321.96 C 123.282 331.86 124.082 343.46 129.882 352.76 C 135.682 362.06 145.982 367.56 156.982 367.56 L 380.982 367.56 L 380.982 447.56 C 380.982 465.26 395.282 479.56 412.982 479.56 C 430.682 479.56 444.982 465.26 444.982 447.56 L 444.982 367.56 L 476.982 367.56 C 494.682 367.56 508.982 353.26 508.982 335.56 C 508.982 317.86 494.682 303.56 476.982 303.56 L 444.982 303.56 L 444.982 159.56 C 444.982 141.86 430.682 127.56 412.982 127.56 C 395.282 127.56 380.982 141.86 380.982 159.56 L 380.982 303.56 L 207.382 303.56 L 313.982 77.16 Z',
  ],
}
const fa25 = {
  prefix: 'fas',
  iconName: '25',
  icon: [
    320, 512,
    [],
    null,
    'M -36.282 96.426 C -57.782 96.426 -78.482 104.926 -93.682 120.226 L -124.582 151.026 C -137.082 163.526 -157.382 163.526 -169.882 151.026 C -182.382 138.526 -182.382 118.226 -169.882 105.726 L -138.982 74.926 C -111.682 47.726 -74.782 32.426 -36.282 32.426 C 43.818 32.426 108.818 97.426 108.818 177.526 C 108.818 216.026 93.518 252.926 66.318 280.126 L -69.882 416.426 L 108.818 416.426 C 126.518 416.426 140.818 430.726 140.818 448.426 C 140.818 466.126 126.518 480.426 108.818 480.426 L -147.182 480.426 C -160.082 480.426 -171.782 472.626 -176.782 460.626 C -181.782 448.626 -178.982 434.926 -169.882 425.726 L 21.018 234.926 C 36.218 219.726 44.818 199.026 44.818 177.526 C 44.818 132.726 8.518 96.426 -36.282 96.426 ZM 187.342 57.397 C 190.142 42.197 203.342 31.097 218.842 31.097 L 410.842 31.097 C 428.542 31.097 442.842 45.397 442.842 63.097 C 442.842 80.797 428.542 95.097 410.842 95.097 L 245.542 95.097 L 225.142 207.097 L 338.842 207.097 C 413.942 207.097 474.842 267.997 474.842 343.097 C 474.842 418.197 413.942 479.097 338.842 479.097 L 255.342 479.097 C 215.942 479.097 179.942 456.797 162.342 421.597 L 158.242 413.397 C 150.342 397.597 156.742 378.397 172.542 370.497 C 188.342 362.597 207.542 368.997 215.442 384.797 L 219.542 392.997 C 226.342 406.597 240.142 415.097 255.342 415.097 L 338.842 415.097 C 378.642 415.097 410.842 382.897 410.842 343.097 C 410.842 303.297 378.642 271.097 338.842 271.097 L 186.842 271.097 C 177.342 271.097 168.342 266.897 162.242 259.597 C 156.142 252.297 153.642 242.697 155.342 233.397 L 187.342 57.397 Z',
  ],
}

library.add(fa10)
library.add(fa11)
library.add(fa12)
library.add(fa13)
library.add(fa14)
library.add(fa15)
library.add(fa16)
library.add(fa17)
library.add(fa18)
library.add(fa19)
library.add(fa20)
library.add(fa21)
library.add(fa22)
library.add(fa23)
library.add(fa24)
library.add(fa25)

library.add(fas)
Vue.component('font-awesome-icon', FontAwesomeIcon)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

Vue.use(VueMask)

Vue.use(VueSocialSharing)
VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)

Vue.use(require('vue-moment'))

require('@core/assets/fonts/feather/iconfont.css')

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
